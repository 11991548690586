<template>
  <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
    <v-row class="app-item-preview">
      <v-col cols="8" md="4">
        <v-text-field
          v-model="OptionData.Name"
          outlined
          dense
          :rules="[validators.required]"
          label="Name"
          placeholder="Name"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="8" md="4">
        <v-text-field
          type="Number"
          v-model="OptionData.Price"
          label="Price"
          prefix="$"
          @focus="$event.target.select()"
        ></v-text-field
      ></v-col>
      <v-col cols="4" md="2">
        <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
      </v-col>
    </v-row>
    <br />
  </v-form>
</template>

<script>
import { AddBranchAddon } from '@core/api/Addons'
import { lengthValidator, required } from '@core/utils/validation'
import { mdiClockTimeFourOutline, mdiClose, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
export default {
  props: {
    Addons: { type: Array, required: true },
    BranchId: { type: Number, required: true },
    AddonId: { type: Number, required: true },
  },
  setup(props, { emit }) {
    const blankOptionData = {
      Id: undefined,
      Name: '',
      Price: 0,
      disabled: false,
    }

    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const OptionData = ref(JSON.parse(JSON.stringify(blankOptionData)))
    const resetOptionData = () => {
      OptionData.value = JSON.parse(JSON.stringify(blankOptionData))
      resetForm()
    }

    const onSubmit = () => {
      if (valid.value) {
        OptionData.value.Id =
          props.Addons.find(obj => {
            return obj.Id == props.AddonId
          }).Options.length + 1

        let AddonsList = [...props.Addons]
        AddonsList.find(obj => {
          return obj.Id == props.AddonId
        }).Options.push(OptionData.value)

        AddBranchAddon({ BranchId: props.BranchId, Addon: JSON.stringify(AddonsList) }).then(() => {
          emit('refetch-data')
        })
      } else {
        validate()
      }
    }

    return {
      resetOptionData,
      form,
      onSubmit,
      OptionData,
      valid,
      validate,
      mdiPlus,

      // validation
      validators: { required, lengthValidator },
      icons: {
        mdiClose,
        mdiClockTimeFourOutline,
      },
    }
  },
}
</script>
