<template>
  <v-row>
    <v-col cols="12">
      <v-row class="mb-4 me-3">
        <v-col cols="10"> </v-col>
        <v-col cols="2"><vc-menu-add-list :BranchId="parseInt($router.currentRoute.params.id)" /></v-col>
      </v-row>
      <option-delete
        @refetch-data="fetchAddons"
        :BranchId="parseInt($router.currentRoute.params.id)"
        :Addons="AddonListTable"
        :Id="optionTemp.Id"
        :AddonId="AddonIdTemp"
        v-model="isDialogDelete"
      />
      <option-edit
        @refetch-data="fetchAddons"
        :BranchId="parseInt($router.currentRoute.params.id)"
        :Option="optionTemp"
        :AddonId="AddonIdTemp"
        :Addons="AddonListTable"
        v-model="isDialogEdit"
      />
      <!-- Main Card -->
      <app-card-actions @refresh="fetchAddons">
        <template slot="title"> Option List </template>

        <v-card-text>
          <v-expansion-panels popout>
            <v-expansion-panel v-for="addon in AddonListTable" :key="addon.Id">
              <v-expansion-panel-header>{{ addon.Name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <option-add-new
                  @refetch-data="fetchAddons"
                  :Addons="AddonListTable"
                  :AddonId="addon.Id"
                  :BranchId="parseInt($router.currentRoute.params.id)"
                ></option-add-new>

                <v-data-table
                  :headers="tableColumns"
                  :items="addon.Options"
                  :options.sync="options"
                  :server-items-length="totalAddonListTable"
                >
                  <!-- Id -->
                  <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>
                <template #[`item.Price`]="{ item }">
                    <div class="d-flex align-center">
                      <div class="d-flex flex-column ms-3">
                        <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.Price }}</span>
                      </div>
                    </div>
                  </template>
                  <!-- Actions -->
                  <template #[`item.Actions`]="{ item }">
                    <div class="demo-space-x">
                      <v-btn
                        icon
                        color="error"
                        @click.stop="
                          ;(isDialogDelete = !isDialogDelete), (optionTemp = { ...item }), (AddonIdTemp = addon.Id)
                        "
                      >
                        <v-icon small>
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="primary"
                        @click.stop="
                          ;(isDialogEdit = !isDialogEdit), (optionTemp = { ...item }), (AddonIdTemp = addon.Id)
                        "
                      >
                        <v-icon small> {{ icons.mdiPencilOutline }} </v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </app-card-actions>
    </v-col></v-row
  >
</template>
<script>
import AppCardActions from '@/@core/components/app-card-actions/AppCardActions.vue'
import VcMenuAddList from '@/components/vc-menu-add-list/VcMenuAddList.vue'
import { mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiPencilOutline, mdiPlus, mdiTrendingUp } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import OptionAddNew from './OptionAddNew.vue'
import OptionDelete from './OptionDelete.vue'
import OptionEdit from './OptionEdit.vue'
import useOptionList from './useOptionList'

export default {
  components: {
    OptionAddNew,
    VcMenuAddList,
    AppCardActions,
    OptionDelete,
    OptionEdit,
  },
  setup() {
    onMounted(() => {
      fetchAddons()
    })
    const { AddonListTable, tableColumns, searchQuery, totalAddonListTable, options, AddonTotalLocal, fetchAddons } =
      useOptionList()
    const optionTemp = ref({ Id: 0 })
    const AddonIdTemp = ref(0)

    const isDialogDelete = ref(false)
    const isDialogEdit = ref(false)
    return {
      AddonListTable,
      optionTemp,
      AddonIdTemp,
      tableColumns,
      searchQuery,
      isDialogDelete,
      isDialogEdit,
      totalAddonListTable,
      options,
      AddonTotalLocal,
      fetchAddons,
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.app-invoice-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-invoice-to {
    width: 190px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
